import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ListItemComponent} from './list-item/list-item.component';
import {ListItemSimpleComponent} from './list-item-simple/list-item-simple.component';
import {ListItemKeyValueComponent} from './list-item-key-value/list-item-key-value.component';
import {AgreementsSignComponent} from './agreements-sign/agreements-sign.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SwitchComponent} from './controls/switch/switch.component';
import {ButtonRadioGroupComponent} from './controls/button-radio-group/button-radio-group.component';
import {GoogleMapsComponent} from './google-maps/google-maps.component';
import {KlarnaComponent} from './klarna/klarna.component';
import {KlarnaPaymentOptionComponent} from './klarna/klarna-payment-option.component';
import {KlarnaPlaygroundComponent} from './klarna/klarna-playground.component';
import {RouterModule} from '@angular/router';
import {PageTitleComponent} from './page-title/page-title.component';
import {CourtesyVehicleExtraComponent} from './courtesy-vehicle-extra/courtesy-vehicle-extra.component';
import {ExtrasSelectComponent} from './extras-order/extras-select.component';
import {CheckboxCircleComponent} from './controls/checkbox-circle/checkbox-circle.component';
import {AgreementsSignVerdictComponent} from './agreement-sign-verdict/agreements-sign-verdict.component';
import {FadeInDirective} from './behavoiur/fade-in.directive';
import {FormInputComponent} from './controls/form-input/form-input.component';
import {TranslateOrUnknownPipe} from './pipes/translate-or-unknown.pipe';
import {CheckboxComponent} from './controls/checkbox/checkbox.component';
import {CollapsibleBox} from './collapsible-box.component';
import {RBGridRowComponent} from './grid/grid-row.component';
import {RBGridComponent} from './grid/grid.component';
import {FileUrlDirective} from './file-url.directive';
import {MyBookingTranslatePipe} from './pipes/my-booking-translate.pipe';
import {RebookingFeatureDirective} from './features/rebooking-feature.directive';
import {RebookUrlDirective} from './urls/rebook-url.directive';
import {RebookingFeatureDisabledDirective} from './features/rebooking-feature-disabled.directive';
import {NgxMaskDirective} from 'ngx-mask';
import {CapitalizeFirstPipe} from "./pipes/capitalize-first.pipe";

@NgModule({
  declarations: [
    AgreementsSignVerdictComponent,
    AgreementsSignComponent,
    CapitalizeFirstPipe,
    CheckboxCircleComponent,
    ExtrasSelectComponent,
    FadeInDirective,
    ListItemComponent,
    ListItemSimpleComponent,
    ListItemKeyValueComponent,
    SwitchComponent,
    ButtonRadioGroupComponent,
    GoogleMapsComponent,
    KlarnaComponent,
    KlarnaPaymentOptionComponent,
    KlarnaPlaygroundComponent,
    PageTitleComponent,
    CourtesyVehicleExtraComponent,
    FormInputComponent,
    TranslateOrUnknownPipe,
    CheckboxComponent,
    CollapsibleBox,
    RBGridComponent,
    RBGridRowComponent,
    FileUrlDirective,
    MyBookingTranslatePipe,
    RebookingFeatureDirective,
    RebookingFeatureDisabledDirective,
    RebookUrlDirective,
  ],
  exports: [
    AgreementsSignVerdictComponent,
    AgreementsSignComponent,
    ExtrasSelectComponent,
    CapitalizeFirstPipe,
    CheckboxCircleComponent,
    FadeInDirective,
    FormInputComponent,
    ListItemComponent,
    ListItemSimpleComponent,
    ListItemKeyValueComponent,
    ButtonRadioGroupComponent,
    GoogleMapsComponent,
    KlarnaComponent,
    KlarnaPaymentOptionComponent,
    KlarnaPlaygroundComponent,
    PageTitleComponent,
    CourtesyVehicleExtraComponent,
    CheckboxComponent,
    CollapsibleBox,
    RBGridComponent,
    RBGridRowComponent,
    FileUrlDirective,
    MyBookingTranslatePipe,
    RebookingFeatureDirective,
    RebookUrlDirective,
    RebookingFeatureDisabledDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxMaskDirective,
  ],
})
export class SharedModule {
}
