import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BookingStoreService} from '../../domain/booking/booking-store.service';
import {Booking} from '../../domain/booking/booking';

@Pipe({
  name: 'myBookingTranslate',
  pure: false
})
export class MyBookingTranslatePipe implements PipeTransform {

  constructor(private bookingStore: BookingStoreService, private translate: TranslateService) {
  }

  transform(value: string, ...args: unknown[]): string {
    const booking = this.bookingStore.get();
    const interpolation = this.interpolate(booking);
    const instant = this.translate.instant(value, interpolation);
    if (typeof instant === 'object') {
      const jobType = booking?.type.replace('JOB_TYPE_', '');
      const key =  (jobType === 'REPAIR' && !booking?.reserved_time) ? 'DROPIN' : jobType;

      if (key && instant[key]) {
        return this.translate.instant(`${value}.${key}`, interpolation);
      }

      return this.translate.instant(`${value}.DEFAULT`, interpolation);
    }
    return instant;
  }

  private interpolate(booking: Booking | null): { [key: string]: any } {
    return booking ? {
      state: this.translate.instant(booking.state.code),
      jobType: this.translate.instant(booking.type),
      vehicleInfo: booking.vehicle.getSummary(),
      date: booking.displayReservedDateWithYear,
      arrivalDate: booking.displayArrivalDate,
      opensAt: booking.workshop.opens_at,
      arriveBefore: booking.workshop.arrive_before,
      arrive: booking.reserved_time
    } : {};
  }
}
