<div class="well2 p-20pt flex flex-column gap-15pt text-xl" [ngClass]="hostClasses">
  <div *ngIf="!booking?.isConfirmed">{{ "MYBOOKING.CALENDAR.STATE" | myBookingTranslate }}</div>
  <ng-container *ngIf="booking?.isConfirmedOrPrev()">

    <ng-container *ngIf="booking?.isReplacement">
      <div>{{ booking?.displayReservedDateWithYear | capitalizeFirst }}</div>
      <div *ngIf="!booking.arrives_day_before && !booking?.isMorningExpress && !booking.isAfternoonExpress">
        {{
          "MYBOOKING.CALENDAR.DATE_AND_ARRIVAL.DEFAULT" | translate: {
            arriveBefore: booking?.workshop.arrive_before,
            opensAt: booking?.workshop.opens_at,
          }
        }}
      </div>

      <ng-container *ngIf="booking?.arrives_day_before">
        <div>
          {{
            "MYBOOKING.CALENDAR.DAY_BEFORE_ARRIVAL.DATE_AND_ARRIVAL" | translate: {
              arrivalDate: booking?.displayArrivalDate,
              arriveAfter: booking?.workshop.arrive_before_close_day_before,
              closesAt: booking?.workshop.closes_at_day_before
            }
          }}
        </div>
        <div>
          {{
            "MYBOOKING.CALENDAR.DAY_BEFORE_ARRIVAL.DATE_AND_ARRIVAL_2" | translate: {
              date: booking?.displayReservedDateWithMonth,
              closesAt: booking?.workshop.closes_at_day_before
            }
          }}
        </div>
      </ng-container>

      <div *ngIf="booking?.isMorningExpress">
        {{
          "MYBOOKING.CALENDAR.MORNING_EXPRESS.DATE_AND_ARRIVAL" | translate: {
            arriveBefore: booking?.workshop.arrive_before,
            opensAt: booking?.workshop.opens_at,
            carReadyTime: booking?.workshop.morning_departure_time
          }
        }}
      </div>

      <div *ngIf="booking?.isAfternoonExpress">
        {{
          "MYBOOKING.CALENDAR.AFTERNOON_EXPRESS.DATE_AND_ARRIVAL" | translate: {
            arriveBefore: booking?.workshop.afternoon_arrival_time,
            closesAt: booking?.workshop.closes_at
          }
        }}
      </div>
    </ng-container>

    <div *ngIf="!booking?.isReplacement">
      {{ "MYBOOKING.CALENDAR.DATE_AND_ARRIVAL" | myBookingTranslate | capitalizeFirst}}
    </div>
  </ng-container>
  <div>{{ "MYBOOKING.CALENDAR.JOB_TYPE" | myBookingTranslate }}</div>
  <div>{{ "MYBOOKING.CALENDAR.VEHICLE_INFO" | myBookingTranslate }}</div>
  <div *ngIf="booking?.isConfirmed && ical"><a [caryFileUrl]="ical" mediaType="text/calendar">
    <i class="fa fa-calendar mr-5pt"></i><span translate>MYBOOKING.CALENDAR.EXPORT_CALENDAR</span></a></div>
</div>

