import { Pipe, PipeTransform } from '@angular/core';
import {StringUtil} from "../../core/string-util";

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(value: string | Date): string {
    if (typeof value !== 'string') {
      return '';
    }
    return StringUtil.capitalizeFirstLetter(value);
  }
}
